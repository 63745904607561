var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"filters-selector"}},[_c('div',[_c('h2',[_vm._v("Grade")]),_vm._v(" "),_c('ul',[_vm._l((_vm.grades),function(grade,index){return _c('li',{key:index},[_c('button',{staticClass:"grade",class:{ selected: _vm.filters.grade[0] === grade || _vm.filters.grade[1] === grade },attrs:{"disabled":_vm.isStockOnly && grade !== 'a'},on:{"click":function($event){return _vm.setGrade(grade)}}},[_vm._v("\n          "+_vm._s(grade === "ap" ? "Grade A+" 
            : grade === 'a/ap' ? "Grades A/A+" 
            : "Grade " + _vm.makeSentenceCase(grade))+"\n        ")])])}),_vm._v(" "),_c('li',[_c('button',{staticClass:"grade",class:{ selected: _vm.filters.grade[0] === '' },attrs:{"disabled":_vm.isStockOnly},on:{"click":function($event){return _vm.setGrade('')}}},[_vm._v("\n          All\n        ")])])],2),_vm._v(" "),_c('h2',[_vm._v("Color")]),_vm._v(" "),_c('ul',{staticClass:"filters-color-wrapper uk-flex uk-flex-wrap uk-child-width-1-2"},[_vm._l((_vm.colors),function(color,index){return _c('li',{key:index},[_c('button',{class:[
            'filter-color-' + color.toLowerCase(),
            _vm.highlightColor(color) ? 'selected' : ''
          ],on:{"click":function($event){return _vm.setColor(color)}}},[_c('p',[_vm._v(_vm._s(_vm.makeSentenceCase(color)))])])])}),_vm._v(" "),_c('li',[_c('button',{class:[
            'filter-color-white',
            _vm.filters.color.length < 1 ? 'selected' : ''
          ],on:{"click":function($event){return _vm.setColor('')}}},[_c('p',[_vm._v("All")])])])],2),_vm._v(" "),_c('h2',[_vm._v("Design")]),_vm._v(" "),_c('ul',[_vm._l((_vm.designs),function(design,index){return _c('li',{key:index},[_c('button',{staticClass:"design",class:{ selected: _vm.filters.design === design },attrs:{"disabled":(_vm.solidsOnly && design !== 'Solids') ||
              (_vm.noStripes && design === 'Stripes')},on:{"click":function($event){return _vm.setDesign(design)}}},[_vm._v("\n          "+_vm._s(_vm.makeSentenceCase(design))+"\n        ")])])}),_vm._v(" "),_c('li',[_c('button',{staticClass:"design",class:{ selected: !_vm.solidsOnly && _vm.filters.design === '' },attrs:{"disabled":_vm.solidsOnly},on:{"click":function($event){return _vm.setDesign('')}}},[_vm._v("\n          All\n        ")])])],2),_vm._v(" "),_c('h2',[_vm._v("Manufacturer")]),_vm._v(" "),_c('ul',[_vm._l((_vm.mfrs),function(mfr,index){return _c('li',{key:index},[_c('button',{staticClass:"mfr",class:{ selected: _vm.filters.mfr === mfr },on:{"click":function($event){return _vm.setMfr(mfr)}}},[_vm._v("\n          "+_vm._s(_vm.makeSentenceCase(mfr))+"\n        ")])])}),_vm._v(" "),_c('li',[_c('button',{staticClass:"mfr",class:{ selected: _vm.filters.mfr === '' },on:{"click":function($event){return _vm.setMfr('')}}},[_vm._v("\n          All\n        ")])])],2),_vm._v(" "),_c('h2',[_vm._v("Weight")]),_vm._v(" "),_c('ul',{staticClass:"weight"},[_vm._l((_vm.weights),function(weight,index){return _c('li',{key:index},[_c('button',{staticClass:"weight",class:{ selected: _vm.isSpecialFabricComponent ? '' : _vm.filters.weight === weight },attrs:{"disabled":!_vm.isSpecialFabricComponent && _vm.mainFabric[0].weight !== weight},on:{"click":function($event){return _vm.setWeight(weight)}}},[_vm._v("\n          "+_vm._s(weight)+" oz.\n        ")])])}),_vm._v(" "),_c('li',[_c('button',{staticClass:"weight",class:{ selected: _vm.filters.weight === '' },attrs:{"disabled":!_vm.isSpecialFabricComponent},on:{"click":function($event){return _vm.setWeight('')}}},[_vm._v("\n          All\n        ")])])],2)])])}
var staticRenderFns = []

export { render, staticRenderFns }